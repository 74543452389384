import React from 'react';

// Layout Imports
import Default from '../layouts/Default/Default';
import SEO from '../layouts/SEO/SEO';

// Component Imports
import PageHeader from '../components/shared/PageHeader/PageHeader';
import Section from '../components/shared/Section/Section';

const Cookies = (props) => {
    return (
        <Default
            location={props.location}
            title='Cookie Policy'
        >
            <SEO
                title='Cookie Policy'
                keywords={[`blog`, `gatsby`, `javascript`, `react`]}
            />

            <PageHeader heading='Cookie Policy' />

            <Section accent>
                <ol className='list'>
                    <li className='list-item'>
                        <h2 className='heading'>Introduction</h2>
                        <ul className='list'>
                            <li className='list-item'>Our website uses cookies.</li>
                            <li className='list-item'>Insofar as those cookies are not strictly necessary for the provision of our website and services, we will ask you to consent to our use of cookies when you first visit our website.</li>
                        </ul>
                    </li>

                    <li className='list-item'>
                        <h2 className='heading'>About cookies</h2>
                        <ul className='list'>
                            <li className='list-item'>A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.</li>
                            <li className='list-item'>Cookies may be either "persistent" cookies or "session" cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.</li>
                            <li className='list-item'>Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be aed to the information stored in and obtained from cookies.</li>
                        </ul>
                    </li>

                    <li className='list-item'>
                        <h2 className='heading'>Cookies that we use</h2>
                        <p>We use cookies for the following purposes:</p>
                        <ul className='list'>
                            <li className='list-item'>analysis - we use cookies to help us to analyse the use and performance of our website and services; and</li>
                        </ul>
                    </li>

                    <li className='list-item'>
                        <h2 className='heading'>Cookies used by our service providers</h2>
                        <ul className='list'>
                            <li className='list-item'>Our service providers use cookies and those cookies may be stored on your computer when you visit our website.</li>
                            <li className='list-item'>We use Google Analytics to analyse the use of our website. Google Analytics gathers information about website use by means of cookies. The information gathered relating to our website is used to create reports about the use of our website. Google's privacy policy is available at: <a className='link' href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.</li>
                            <li className='list-item'>We publish Google AdSense advertisements on our website. To determine your interests, Google will track your behaviour on our website and on other websites across the web using cookies. This behaviour tracking allows Google to tailor the advertisements that you see on other websites to reflect your interests (but we do not publish interest-based advertisements on our website). You can view, delete or add interest categories associated with your browser by visiting: <a className='link' href="https://adssettings.google.com">https://adssettings.google.com</a>. You can also opt out of the AdSense partner network cookie using those settings or using the Network Advertising Initiative's multi-cookie opt-out mechanism at: <a className='link' href="http://optout.networkadvertising.org">http://optout.networkadvertising.org</a>. However, these opt-out mechanisms themselves use cookies, and if you clear the cookies from your browser your opt-out will not be maintained. To ensure that an opt-out is maintained in respect of a particular browser, you may wish to consider using the Google browser plug-ins available at: <a className='link' href="https://support.google.com/ads/answer/7395996">https://support.google.com/ads/answer/7395996</a>.</li>
                        </ul>
                    </li>

                    <li className='list-item'>
                        <h2 className='heading'>Managing cookies</h2>
                        <ul className='list'>
                            <li className='list-item'>
                                Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can however obtain up-to-date information about blocking and deleting cookies via these as:
                                <ul className='list'>
                                    <li className='list-item'><a className='link' href="https://support.google.com/chrome/answer/95647?hl=en">https://support.google.com/chrome/answer/95647?hl=en</a> (Chrome);</li>
                                    <li className='list-item'><a className='link' href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a> (Firefox);</li>
                                    <li className='list-item'><a className='link' href="http://www.opera.com/help/tutorials/security/cookies/">http://www.opera.com/help/tutorials/security/cookies/</a> (Opera);</li>
                                    <li className='list-item'><a className='link' href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies</a> (Internet Explorer);</li>
                                    <li className='list-item'><a className='link' href="https://support.apple.com/kb/PH21411">https://support.apple.com/kb/PH21411</a> (Safari); and</li>
                                    <li className='list-item'><a className='link' href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy</a> (Edge).</li>
                                </ul>
                            </li>
                            <li className='list-item'>Blocking all cookies will have a negative impact upon the usability of many websites.</li>
                            <li className='list-item'>If you block cookies, you may not be able to use all the features on our website.</li>
                        </ul>
                    </li>

                    <li className='list-item'>
                        <h2 className='heading'>Our details</h2>
                        <ul className='list'>
                            <li className='list-item'>This website is owned and operated by ParkyGames.</li>
                            <li className='list-item'>
                                You can contact us:
                                <ul className='list'>
                                    <li className='list-item'>via our social media accounts published on our website from time to time.</li>
                                    <li className='list-item'>by email, using the email address published on our website from time to time.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ol>
            </Section>
        </Default>
    );
};

export default Cookies;